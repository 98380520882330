import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useInView } from "react-intersection-observer";
import { GatsbyImage } from "gatsby-plugin-image";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../../components/global/page-seo";

// Components
import { DetermineModule } from "../../components/utils/determine-module";

// Components
import { ListProjects } from "../../components/activities/list-projects";
import { ListActivities } from "../../components/activities/list-activities";

// Context
import { CurrentLanguage } from "../../components/context/current-language";
import { HeaderColor } from "../../components/context/header-color";
import { PageColor } from "../../components/context/page-color";
import { PageContext } from "../../components/context/page-context";

const Page = styled.div`
  background-color: #efefef;

  & .dropdown-container {
    margin: 0 0 100px 0;

    & .project-filter-select {
      max-width: 390px;
      width: 100%;

      & .project-filter__control,
      & .project-filter__menu {
        border-color: #09184f;
        border-radius: 0;
        background-color: #efefef;
        box-shadow: none;
      }

      & .project-filter__placeholder {
        color: #09184f;
      }

      & .project-filter__indicator-separator {
        display: none;
      }

      & .project-filter__indicator {
        color: #09184f;
      }

      & .project-filter__menu {
        border: 1px solid #09184f;
        margin: 0;
      }

      & .project-filter__menu-list {
        padding: 0;
      }

      & .project-filter__option--is-focused,
      & .project-filter__option--is-selected {
        background-color: #09184f;
        color: #fff;
      }
    }

    @media (max-width: 800px) {
      margin: 0 0 90px 0;
    }

    @media (max-width: 767px) {
      padding: 0 25px;
    }
  }
`;

const Introduction = styled.div`
  &.projects-view {
    padding: 100px 40px 90px 40px;

    @media (max-width: 800px) {
      padding: 31px 25px 50px 25px;
    }
  }

  &.activities-view {
    padding: 151px 40px;

    @media (max-width: 1445px) {
      padding: 100px 40px;
    }

    @media (max-width: 999px) {
      padding: 31px 25px 150px 25px;
    }

    @media (max-width: 800px) {
      padding: 31px 25px 60px 25px;
    }
  }

  & .title-container {
    margin: 0 0 40px 0;

    @media (max-width: 1445px) {
      margin: 0 0 35px 0;
    }

    & h1 {
      font-size: 70px;
      line-height: 70px;

      @media (max-width: 999px) {
        font-size: 40px;
        line-height: 50px;
      }
    }

    @media (max-width: 800px) {
      margin: 0 0 10px 0;
    }
  }

  & .text-container {
    max-width: 1290px;

    & h3 {
      @media (max-width: 800px) {
        font-size: 19px;
        line-height: 24px;
      }
    }
  }
`;

const Article = styled.div`
  & .image-container {
    overflow: hidden;
    cursor: pointer;

    & img:not([aria-hidden="true"]) {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border: 39px solid #fff;
      box-sizing: border-box;

      @media (max-width: 999px) {
        border: 27px solid #fff;
      }
    }
  }

  & .title-bar {
    display: flex;
    flex-direction: row;

    & p {
      margin: 0;
    }

    & .spacer {
      margin: 0 10px;
    }
  }

  & .text-container {
    margin: 30px 0 0 0;
    padding: 0 39px;

    & .article-title {
      margin: 15px 0 20px 0;
    }

    & .article-excerpt {
      margin: 20px 0 15px 0;
    }

    @media (max-width: 999px) {
      padding: 0 27px;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    }
  }
`;

const Container = styled.div`
  padding: 90px 0 0 0;

  @media (max-width: 800px) {
    padding: 50px 0 0 0;
  }

  & .content-container {
    max-width: 1280px;
    margin: 0 auto;

    @media (max-width: 999px) {
      &.grid-6 {
        grid-row-gap: 100px;
      }
    }
  }

  & .single-article {
    &:nth-of-type(4n + 1) {
      grid-column: 1 / 4;
    }

    &:nth-of-type(4n + 2) {
      grid-column: 5 / 7;
    }

    &:nth-of-type(4n + 3) {
      grid-column: 1 / 3;
    }

    &:nth-of-type(4n + 4) {
      grid-column: 4 / 7;
    }

    @media (max-width: 999px) {
      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 3) {
        grid-column: 1 / 5;
      }

      &:nth-of-type(4n + 2),
      &:nth-of-type(4n + 4) {
        grid-column: 4 / 7;
      }
    }

    @media (max-width: 767px) {
      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 3),
      &:nth-of-type(4n + 2),
      &:nth-of-type(4n + 4) {
        grid-column: 1 / 7;
      }
    }
  }

  & .view-more-articles-container {
    width: 100%;
    margin: 150px 0;

    & button {
      display: block;
      margin: 0 auto;

      border: 1px solid #09184f;

      height: 50px;
      line-height: 50px;
      width: 275px;
    }
  }

  @media (max-width: 767px) {
    &.list-activities {
      padding: 0;
    }
  }
`;

const ContentContainer = styled.div`
  background-color: #fff;
  padding: 150px 0;

  @media (max-width: 800px) {
    padding: 100px 0;
  }
`;

const ActivityCategory = ({ data }) => {
  const [projectsFilter, setProjectsFilter] = useState(`all`);
  const [activeProject, setActiveProject] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);
  const [pageContext, setPageContext] = useContext(PageContext);

  const { ref, inView, entry } = useInView({
    trackVisibility: true,
    delay: 100,
    threshold: [0.1],
  });

  useEffect(() => {
    setPageColor(`#efefef`);
    setHeaderColor(`#efefef`);
  }, []);

  useEffect(() => {
    if (pageContext.isMenuOpen === true) {
      setHeaderColor(`#fff`);
    } else {
      if (headerColor === `#fff`) {
        setHeaderColor(`#efefef`);
      }
    }
  }, [pageContext]);

  // useEffect(() => {
  //   if (entry !== undefined) {
  //     if (
  //       entry.boundingClientRect.top <= 79 &&
  //       entry.isIntersecting &&
  //       pageContext.isMenuOpen == false
  //     ) {
  //       setHeaderColor(`#efefef`);
  //     } else {
  //       if (headerColor !== `#fff`) {
  //         setHeaderColor(`#fff`);
  //       }
  //     }
  //   }
  // }, [entry]);

  const SingleProject = ({ project, index, backLink }) => {
    const categories = project.data.categories
      .filter(
        (category) =>
          category.category.document !== null &&
          category.category.document !== undefined
      )
      .filter(
        (category) =>
          category.category.document.data !== null &&
          category.category.document.data !== undefined
      )
      .map((category, index) => (
        <span key={`single_article_category_${index}_${project.id}`}>
          <Link to={category.category.document.url}>
            {category.category.document.data.title.text}
          </Link>
        </span>
      ));

    return (
      <Article className="single-article">
        <div className="image-container">
          <Link to={project.url} state={{ backLink: backLink }}>
            <GatsbyImage
              image={project.data.thumbnail.gatsbyImageData}
              alt={
                project.data.thumbnail.alt !== null
                  ? project.data.thumbnail.alt
                  : ``
              }
              loading={index > 3 ? `lazy` : `eager`}
            />
          </Link>
        </div>

        <div className="text-container">
          <div className="title-bar tag gotham-bold uppercase">
            <p>Activities</p>
            <p className="spacer">|</p>
            <p>{categories}</p>
          </div>

          <PrismicRichText
            field={project.data.title.richText}
            components={{
              heading1: ({ children }) => (
                <h3 className="article-title">{children}</h3>
              ),
            }}
          />
          <PrismicRichText
            field={project.data.excerpt_text.richText}
            components={{
              paragraph: ({ children }) => (
                <p className="article-excerpt">{children}</p>
              ),
            }}
          />

          <Link to={project.url} state={{ backLink: backLink }}>
            {currentLanguage === `en` ? `See More` : `Voir Plus`}
          </Link>
        </div>
      </Article>
    );
  };

  const projectFilterDataEnglish = Array.from(
    new Set(
      data.prismicActivityCategory.data.projects
        .filter(
          (project) =>
            project.project.document !== undefined &&
            project.project.document !== null
        )
        .filter(
          (project) => project.project.document.data.project_type !== null
        )
        .map((project) => project.project.document.data.project_type)
    )
  ).map((tag) => {
    return (tag = {
      value: tag.toLowerCase(),
      label: tag,
    });
  });

  const projectFilterDataFrench = Array.from(
    new Set(
      data.prismicActivityCategory.data.projects
        .filter(
          (project) =>
            project.project.document !== undefined &&
            project.project.document !== null
        )
        .filter(
          (project) =>
            project.project.document.data.project_type_french !== null
        )
        .map((project) => project.project.document.data.project_type_french)
    )
  ).map((tag) => {
    return (tag = {
      value: tag.toLowerCase(),
      label: tag,
    });
  });

  const projects = data.prismicActivityCategory.data.projects
    .filter(
      (project) =>
        project.project.document !== undefined &&
        project.project.document !== null
    )
    .filter((project) => {
      if (projectsFilter === `all` || projectsFilter.value === `all`) {
        return project;
      } else {
        if (currentLanguage === `en`) {
          if (
            project.project.document.data.project_type === projectsFilter.label
          ) {
            return project;
          }
        } else {
          if (
            project.project.document.data.project_type_french ===
            projectsFilter.label
          ) {
            return project;
          }
        }
      }
    })
    .map((project, index) => (
      <SingleProject
        index={index}
        project={project.project.document}
        key={`single_article_container_${index}_${data.prismicActivityCategory.id}`}
        setActiveProject={setActiveProject}
        setIsPopupOpen={setIsPopupOpen}
        backLink={data.prismicActivityCategory.url}
      />
    ));

  const content = data.prismicActivityCategory.data.body.map(
    (content, index) => (
      <DetermineModule
        content={content}
        index={index}
        key={`single_module_${index}_${data.prismicActivityCategory.id}`}
        currentLanguage={currentLanguage}
      />
    )
  );

  return (
    <>
      <PageSeo
        title={data.prismicActivityCategory.data.title.text}
        image={null}
        description={null}
        url={data.prismicActivityCategory.url}
      />

      <Page>
        <Introduction
          ref={ref}
          className={
            data.prismicActivityCategory.data.list_projects !== false
              ? `projects-view`
              : `activities-view`
          }
        >
          <div className="title-container">
            <PrismicRichText
              field={data.prismicActivityCategory.data.title.richText}
            />
          </div>

          <div className="text-container">
            <PrismicRichText
              field={data.prismicActivityCategory.data.text.richText}
              components={{
                paragraph: ({ children }) => <h3>{children}</h3>,
              }}
            />
          </div>

          <Container
            className={
              data.prismicActivityCategory.data.list_projects
                ? `list-projects`
                : `list-activities`
            }
          >
            {data.prismicActivityCategory.data.list_projects !== false ? (
              <ListProjects
                projects={projects}
                projectsFilter={projectsFilter}
                setProjectsFilter={setProjectsFilter}
                setActiveProject={setActiveProject}
                activeProject={activeProject}
                setIsPopupOpen={setIsPopupOpen}
                isPopupOpen={isPopupOpen}
                showFilters={data.prismicActivityCategory.data.show_filters}
                projectFilterData={
                  currentLanguage === `en`
                    ? projectFilterDataEnglish
                    : projectFilterDataFrench
                }
              />
            ) : (
              <ListActivities
                data={data.prismicActivityCategory.data.activities}
              />
            )}
          </Container>
        </Introduction>

        <ContentContainer>{content}</ContentContainer>
      </Page>
    </>
  );
};

export default withPrismicPreview(ActivityCategory);

export const query = graphql`
  query ActivityCategoryQuery($id: String, $lang: String) {
    prismicActivityCategory(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        show_filters
        activities {
          activity {
            document {
              ... on PrismicActivity {
                id
                url
                data {
                  title {
                    richText
                  }
                  excerpt_text {
                    richText
                  }
                  thumbnail {
                    fluid {
                      srcSet
                      src
                    }
                    gatsbyImageData
                    alt
                  }
                }
              }
            }
          }
        }
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    richText
                  }
                  thumbnail {
                    alt
                    gatsbyImageData(aspectRatio: 0.71)
                  }
                  excerpt_text {
                    richText
                  }
                  text {
                    richText
                  }
                  images {
                    image {
                      alt
                      dimensions {
                        height
                        width
                      }
                      gatsbyImageData
                      fluid {
                        src
                        srcSet
                        aspectRatio
                      }
                    }
                  }
                  project_type
                  project_type_french
                  categories {
                    category {
                      document {
                        ... on PrismicActivityCategory {
                          id
                          url
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        title {
          richText
          text
        }
        text {
          richText
        }
        list_projects
        body {
          ... on PrismicActivityCategoryDataBodyVideo {
            id
            slice_type
            primary {
              video {
                type
                html
                embed_url
                provider_name
                id
              }
              video_size
            }
          }
          ... on PrismicActivityCategoryDataBodyBigPush {
            id
            slice_type
            primary {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              big_push_title {
                richText
              }
              call_to_action {
                richText
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyCover {
            id
            slice_type
            primary {
              cover_image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
              cover_text {
                richText
              }
              cover_title {
                richText
              }
              cover_call_to_action {
                richText
              }
              image_position
              link {
                url
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyEdit {
            id
            slice_type
            primary {
              background_color
              journal_edit {
                type
                document {
                  ... on PrismicJournalEdit {
                    id
                    data {
                      title {
                        richText
                      }
                      articles {
                        article {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                              }
                            }
                            ... on PrismicArticle {
                              id
                              url
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicJournalCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicActivity {
                              id
                              url
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicProject {
                              id
                              url
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialCover {
            id
            slice_type
            primary {
              editorial_credits {
                richText
              }
              editorial_text {
                richText
              }
              editorial_title {
                richText
              }
              image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  aspectRatio
                }
              }
              image_position
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialImageWithText {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
              text {
                richText
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialImages {
            id
            slice_type
            primary {
              editorial_image_left {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              editorial_image_right {
                dimensions {
                  height
                  width
                }
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              image_left_background
              image_right_background
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialNote {
            id
            slice_type
            primary {
              editorial_note_title
              editorial_note_text {
                richText
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialQuote {
            id
            slice_type
            primary {
              editorial_quote {
                richText
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialRow {
            id
            slice_type
            primary {
              editorial_row_title {
                richText
              }
            }
            items {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 800)
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialText {
            id
            slice_type
            primary {
              editorial_text {
                richText
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyProductRowCarousel {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyProductRow {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyProductCardLarge {
            id
            slice_type
            primary {
              orientation
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
              }
              orientation
            }
          }
          ... on PrismicActivityCategoryDataBodyEditorialIntroduction {
            id
            slice_type
            primary {
              editorial_introduction_text {
                richText
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyPushStory {
            id
            slice_type
            primary {
              background_color
              story {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      thumbnail {
                        alt
                        gatsbyImageData
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                        dimensions {
                          height
                          width
                        }
                      }
                      excerpt_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyTitleAndText {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
          }
          ... on PrismicActivityCategoryDataBodyNewsletter {
            id
            slice_type
            primary {
              newsletter {
                document {
                  ... on PrismicNewsletterSignup {
                    id
                    data {
                      background_color
                      email_placeholder
                      newsletter_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
