import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

// Utils
import { encodeForm } from "../utils/encode-form";

const RefundFormContainer = styled.div`
  & form {
    width: 100%;
    max-width: 1500px;

    margin: 0 auto;
    padding: 50px;

    background-color: #fff;

    & .signature-container,
    & .form-fields-container {
      & input,
      & textarea {
        display: block;
        width: 100%;

        margin: 0 0 20px 0;
        padding: 16px 20px 16px 0;

        border: 0;
        border-bottom: 1px solid #09184f;

        ::-webkit-input-placeholder {
          color: #09184f;
        }

        ::-moz-placeholder {
          color: #09184f;
        }

        :-ms-input-placeholder {
          color: #09184f;
        }

        :-moz-placeholder {
          color: #09184f;
        }
      }

      margin: 0 0 50px 0;
    }

    & .button-container {
      & button {
        display: block;
        width: 260px;
        height: 50px;

        margin: 0 0 0 auto;

        font-size: 12px;

        background: #09184f;
        color: #fff;

        text-transform: uppercase;

        @media (max-width: 500px) {
          width: 100%;
        }

        text-decoration: underline;
        text-decoration-color: transparent;
        text-underline-offset: 0.2em;

        transition: text-decoration-color 300ms;

        &:hover {
          text-decoration-color: #fff;
        }
      }
    }
  }
`;

export const EnglishRefundForm = ({ content, currentLanguage }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [formMessage, setFormMessage] = useState(
    currentLanguage === `en` ? `Submit` : `Envoyer`
  );

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      await fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: encodeForm({
          "form-name": `refund-form-en`,
          subject: `Refund Form Submission`,
          ...data,
        }),
      });

      reset();
      setFormMessage(currentLanguage === `en` ? `Submitted` : `Envoyé`);
    } catch (err) {
      console.error(err);
      setFormMessage(currentLanguage === `en` ? `Error` : `Erreur`);
    }
  };

  useEffect(() => {
    if (formMessage !== `Submit` && formMessage !== `Envoyer`) {
      const closeForm = setTimeout(() => {
        setFormMessage(currentLanguage === `en` ? `Submit` : `Envoyer`);
      }, 10000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage, currentLanguage]);

  const fields = content.items.map((field, index) => (
    <input
      key={`single_${content.id}_form_field_${index}`}
      type="text"
      aria-required="true"
      aria-label={field.form_input_field}
      id={field.form_input_field}
      placeholder={`${field.form_input_field}*`}
      {...register(field.form_input_field, { required: true })}
    />
  ));

  return (
    <RefundFormContainer className="module refund-form">
      <form
        name={`refund-form-en`}
        onSubmit={handleSubmit(onSubmit)}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value={`refund-form-en`} />
        <input type="hidden" name="bot-field" />

        <div className="form-fields-container">{fields}</div>

        <div className="signature-container">
          <p>{content.primary.signature_text}</p>
          <input
            {...register(content.primary.date_text, { required: true })}
            type="text"
            aria-required="true"
            placeholder={content.primary.date_text}
          />
        </div>

        <div className="details-container">
          <p>{content.primary.delete_text}</p>
        </div>

        <div className="button-container">
          <button
            className="submit-button"
            type={
              formMessage === `Submit` || formMessage === `Envoyer`
                ? `submit`
                : `button`
            }
            disabled={formMessage !== `Submit` && formMessage !== `Envoyer`}
          >
            {formMessage}
          </button>
        </div>
      </form>
    </RefundFormContainer>
  );
};
