import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useMeasure } from "react-use";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

const BackgroundImage = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;

  border: 39px solid #efefef;
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  @media (max-width: 999px) {
    border: 27px solid #efefef;
  }

  @media (max-width: 800px) {
    border: 21px solid #fff;
  }
`;

const BigPushContainer = styled.div`
  @media (max-width: 800px) {
    &.module {
      padding: 100px 0;

      background-color: #efefef;
    }
  }

  & .title-container {
    margin: 0 0 80px 0;

    & .call-to-action {
      margin: 25px 0 0 0;

      & p {
        margin: 0;
      }
    }

    @media (max-width: 800px) {
      margin: 0 0 50px 0;

      & .call-to-action {
        margin: 10px 0 0 0;
      }
    }
  }

  & .article-container {
    & .image-container {
      position: relative;

      grid-column: 2 / 12;

      @media (max-width: 999px) {
        grid-column: 1 / 13;
        padding: 0 25px;
      }

      @media (max-width: 800px) {
        grid-column: 1 / 7;
        padding: 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      & .aspect-ratio-container {
        position: relative;

        @media (max-width: 800px) {
          order: 1;
          width: 100%;
        }

        & img:not([aria-hidden="true"]) {
          border: 39px solid #efefef;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          object-fit: cover;

          @media (max-width: 999px) {
            border: 27px solid #efefef;
          }
          @media (max-width: 800px) {
            border: 21px solid #fff;
          }
        }
      }

      & .caption-container {
        max-width: 510px;
        width: 100%;

        position: sticky;
        top: calc(100vh - ${(props) => props.height}px - 39px);

        margin: 0 auto 39px auto;

        text-align: center;
        z-index: 1;

        @media (max-width: 999px) {
          top: calc(100vh - ${(props) => props.height}px - 27px);
          margin: 0 auto 27px auto;
        }

        @media (max-width: 800px) {
          top: calc(100vh - ${(props) => props.height}px - 21px);
          margin: 0 auto 21px auto;
        }

        & .inner-caption-container {
          padding: 40px;
          margin: 39px 0 0 0;

          background-color: #fff;

          @media (max-width: 999px) {
            margin: 27px 0 0 0;
          }

          @media (max-width: 800px) {
            margin: 21px 0 0 0;
          }
        }

        @media (max-width: 800px) {
          position: relative;
          top: 0;
          left: 0;
          transform: unset;

          padding: 40px 40px 0 40px;
          margin: 0 auto;
          background-color: #efefef;

          order: 2;

          & h3 {
            font-size: 30px;
            line-height: 40px;
          }
        }
      }
    }
  }
`;

export const BigPush = ({
  bigPushTitle,
  callToAction,
  article,
  currentLanguage,
  index,
}) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 801px)");
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  if (article.document !== null) {
    return (
      <BigPushContainer className="module big-push" height={height}>
        <div className="title-container center">
          <div className="title">
            <PrismicRichText
              field={bigPushTitle}
              components={{
                heading2: ({ children, index }) => <h1>{children}</h1>,
              }}
            />
          </div>

          <div className="call-to-action small uppercase">
            <PrismicRichText field={callToAction} />
          </div>
        </div>

        {article.document !== null && (
          <div className="article-container grid-12">
            {isDesktop ? (
              <div className="image-container">
                <Link to={article.document.url}>
                  <AspectRatioImageContainer
                    image={article.document.data.thumbnail}
                  >
                    <BackgroundImage
                      image={article.document.data.thumbnail.fluid.src}
                    >
                      <div className="caption-container" ref={ref}>
                        <div className="inner-caption-container">
                          <PrismicRichText
                            field={article.document.data.title.richText}
                            components={{
                              heading1: ({ children, index }) => (
                                <h3 className="article-title">{children}</h3>
                              ),
                            }}
                          />
                          <PrismicRichText
                            field={article.document.data.excerpt_text.richText}
                          />

                          <div className="call-to-action">
                            <p className="uppercase small">
                              {currentLanguage === `en`
                                ? `See More`
                                : `Voir Plus`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </BackgroundImage>
                  </AspectRatioImageContainer>
                </Link>
              </div>
            ) : (
              <div className="image-container">
                {article.document.data.thumbnail.fluid !== null && (
                  <AspectRatioImageContainer
                    image={article.document.data.thumbnail}
                  >
                    <Link to={article.document.url}>
                      <GatsbyImage
                        image={article.document.data.thumbnail.gatsbyImageData}
                        // src={article.document.data.thumbnail.fluid.src}
                        alt={
                          article.document.data.thumbnail.alt !== null
                            ? article.document.data.thumbnail.alt
                            : ``
                        }
                        fetchpriority={index <= 1 ? `high` : ``}
                        loading={index <= 1 ? `eager` : `lazy`}
                      />
                    </Link>
                  </AspectRatioImageContainer>
                )}

                <div className="caption-container">
                  <PrismicRichText
                    field={article.document.data.title.richText}
                    components={{
                      heading1: ({ children, index }) => (
                        <h3 className="article-title">{children}</h3>
                      ),
                    }}
                  />
                  <PrismicRichText
                    field={article.document.data.excerpt_text.richText}
                  />

                  <div className="call-to-action">
                    <Link to={article.document.url} className="uppercase small">
                      {currentLanguage === `en` ? `See More` : `Voir Plus`}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </BigPushContainer>
    );
  } else {
    return null;
  }
};
