import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Context
import { CurrentLanguage } from "../context/current-language";

const ListingsContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 25px;

  padding: 0 0 220px 0;

  & .image-container {
    grid-column: 1 / 5;

    padding: 0 50px 0 0;

    & .sticky-image-container {
      position: sticky;
      top: 119px;
    }

    /* & .content { */
    /* background: white; */
    /* } */

    @media (max-width: 1445px) {
      padding: 0 25px 0 0;
    }

    @media (max-width: 999px) {
      grid-column: 4 / 10;

      padding: 0;
      margin: 0 0 150px 0;
    }

    @media (max-width: 800px) {
      margin: 0 0 60px 0;
    }

    @media (max-width: 600px) {
      grid-column: 2 / 12;
    }
  }

  & ol {
    grid-column: 5 / 13;
    padding: 0 0 0 50px;

    @media (max-width: 1445px) {
      padding: 0 0 0 25px;
    }

    @media (max-width: 999px) {
      padding: 0;
      grid-column: span 12;
    }

    & li {
      display: grid;
      grid-template-columns: auto 470px;

      border-top: 1px solid #09184f;
      padding: 25px 0;

      & .activity-title-container {
        & h2 {
          @media (max-width: 1445px) {
            font-size: 30px;
            line-height: 40px;
          }
        }

        & .tag {
          margin: 5px 0 0 0;
        }
      }

      & .activity-text-container {
        width: 100%;
        max-width: 575px;
        margin: 0 0 0 auto;

        @media (max-width: 1445px) {
          max-width: 470px;
        }

        & p {
          &:first-of-type {
            margin-top: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      @media (max-width: 999px) {
        grid-template-columns: auto 575px;
      }

      @media (max-width: 800px) {
        grid-template-columns: 1fr 2fr;
      }
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: row;

      max-width: 100%;
      grid-template-columns: unset;
      grid-column-gap: unset;

      overflow-x: scroll;
      padding: 0 25px;

      &::-webkit-scrollbar {
        display: none;
      }

      & li {
        display: block;
        grid-template-columns: unset;

        min-width: 50vw;
        margin: 0 40px 0 0;
        border: 0;
        padding: 0;

        &:last-of-type {
          margin: 0;
        }

        & .activity-title-container {
          & .tag {
            margin: 19px 0 55px 0;
          }
        }
      }
    }

    @media (max-width: 500px) {
      & li {
        min-width: 70vw;
        margin: 0 25px 0 0;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 100px 0;
  }
`;

export const ListActivities = ({ data }) => {
  const [activeActivityIndex, setActiveActivityIndex] = useState(0);
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  if (data.length === 0) return null;

  const activities = data
    .filter(
      (activity) =>
        activity.activity.document !== undefined &&
        activity.activity.document !== null
    )
    .map((activity, index) => (
      <li
        key={`single_activity_${index}`}
        onMouseEnter={() => setActiveActivityIndex(index)}
      >
        <div className="activity-title-container">
          <PrismicRichText
            field={activity.activity.document.data.title.richText}
            components={{
              heading1: ({ children, index }) => (
                <h2 className="activity-title">
                  <Link to={activity.activity.document.url}>{children}</Link>
                </h2>
              ),
            }}
          />
          <p className="tag uppercase">
            <Link to={activity.activity.document.url}>
              {currentLanguage === `en` ? `Discover` : `Découvrir`}
            </Link>
          </p>
        </div>

        <div className="activity-text-container">
          <PrismicRichText
            field={activity.activity.document?.data.excerpt_text.richText}
          />
        </div>
      </li>
    ));

  return (
    <>
      <ListingsContainer className="listings-container">
        <div className="image-container">
          {data[activeActivityIndex].activity.document?.data !== undefined && (
            <div className="sticky-image-container">
              {data[activeActivityIndex].activity.document.data.thumbnail
                .fluid !== null && (
                <AspectRatioImageContainer image={null} padding={140}>
                  <GatsbyImage
                    image={
                      data[activeActivityIndex].activity.document.data.thumbnail
                        .gatsbyImageData
                    }
                    alt={
                      data[activeActivityIndex].activity.document.data.thumbnail
                        .alt !== null
                        ? data[activeActivityIndex].activity.document.data
                            .thumbnail.alt
                        : ""
                    }
                  />
                </AspectRatioImageContainer>
              )}
            </div>
          )}
        </div>

        <ol>{activities}</ol>
      </ListingsContainer>
    </>
  );
};
