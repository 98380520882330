import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

// // Components
// import { FlexContainer } from "../containers/flex-container";
// import { DropdownIcon } from "../icons/dropdown-icon";

// Utils
// import { convertToKebabCase } from "../utils/convert-to-kebab";
// import { encodeForm } from "../utils/encode-form";

const EnquiryFormContainer = styled.div`
  margin: 45px 0 0 0;

  @media (max-width: 800px) {
    margin: 90px 0 0 0;
  }

  & form {
    & .field {
      display: grid;
      grid-template-columns: auto 1px 60px;
      align-items: center;

      border: 1px solid ${(props) => props.color};
      max-width: 500px;

      & input {
        width: calc(100% - 15px - 15px);
        padding: 0 15px;

        border: 0;
        background-color: transparent;

        color: ${(props) => props.color};

        font-size: 14px;
        line-height: 50px;

        ::-webkit-input-placeholder {
          color: ${(props) => props.color};
        }
        ::-moz-placeholder {
          color: ${(props) => props.color};
        }
        :-ms-input-placeholder {
          color: ${(props) => props.color};
        }
        :-moz-placeholder {
          color: ${(props) => props.color};
        }
      }

      & .line {
        border-left: 1px solid ${(props) => props.color};
        height: 24px;
      }

      & button {
        width: 60px;
        color: ${(props) => props.color};

        font-size: 12px;
        line-height: 50px;

        // border: 1px solid #323232;
        // padding: 7px;

        // transition: 150ms all ease;

        // &:hover {
        //   color: ${(props) => props.color};
        //   background-color: #323232;
        // }
      }
    }

    & .language-toggle-container {
      & ol {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        & li {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          margin: 10px 20px;

          & label {
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }

  & #thank-you-message {
    height: 52px;

    & p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &.newsletter-form {
    & .language-toggle-container {
      & ol {
        justify-content: flex-start;

        & li {
          margin: 10px 20px 10px 0;
        }
      }
    }
  }
`;

export const MailchimpForm = ({
  currentLanguage,
  formName,
  placeholder,
  color,
}) => {
  // Form Setup
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      LANGUAGE: "English",
    },
  });

  const [formMessage, setFormMessage] = useState(``);

  const onSubmit = (data, e) => {
    e.preventDefault();
    addToMailchimp(data.email, { LANGUAGE: data.LANGUAGE })
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg.includes("already subscribed")) {
            e.target.reset();
            if (currentLanguage === `en`) {
              setFormMessage(
                "Thank you for signing up to the Rinck mailing list."
              );
            } else {
              setFormMessage(
                "Merci de vous être inscrit à la newsletter de Rinck."
              );
            }
          }
        } else {
          e.target.reset();
          if (currentLanguage === `en`) {
            setFormMessage(
              "Thank you for signing up to the Rinck mailing list."
            );
          } else {
            setFormMessage(
              "Merci de vous être inscrit à la newsletter de Rinck."
            );
          }
        }
      })
      .catch(() => {
        console.log("error");
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (formMessage !== ``) {
      const closeForm = setTimeout(() => {
        setFormMessage(``);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  return (
    <EnquiryFormContainer color={color} className={`${formName}-form`}>
      {formMessage === `` ? (
        <form
          name={`${formName}-mailchimp`}
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmit(onSubmit)}
          netlify-honeypot="bot-field"
        >
          <input
            type="hidden"
            name="form-name"
            value={`${formName}-mailchimp`}
          />

          <div className="field">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              aria-required="true"
              aria-label="Email"
              placeholder={placeholder}
              {...register("email", { required: true })}
            />

            <div className="line" />

            <button type="submit" className="uppercase">
              Ok
            </button>
          </div>

          <div className="language-toggle-container">
            <ol>
              <li className="single-language">
                <input
                  {...register("email", { required: true })}
                  type="radio"
                  name="LANGUAGE"
                  id={`mce-LANGUAGE0-${formName}`}
                  value="Français"
                />
                <label htmlFor={`mce-LANGUAGE0-${formName}`}>Français</label>
              </li>

              <li className="single-language">
                <input
                  {...register("email", { required: true })}
                  type="radio"
                  name="LANGUAGE"
                  id={`mce-LANGUAGE1-${formName}`}
                  value="English"
                />

                <label htmlFor={`mce-LANGUAGE1-${formName}`}>English</label>
              </li>
            </ol>
          </div>
        </form>
      ) : (
        <div id="thank-you-message">
          <p>{formMessage}</p>
        </div>
      )}
    </EnquiryFormContainer>
  );
};
