import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

const EditorialCoverContainer = styled.div`
  &.module {
    padding: 30px 80px 65px 80px;

    @media (max-width: 1445px) {
      padding: 0 40px 65px 40px;
    }

    @media (max-width: 800px) {
      padding: 25px 0 0 0;
    }
  }

  &.layout-image-right {
    & .text-container {
      grid-column: 1 / 7;

      @media (max-width: 800px) {
        grid-column: 1 / 7;
        order: 2;

        padding: 39px 25px;
        margin: 0;
      }
    }

    & .image-container {
      grid-column: 7 / 13;

      @media (max-width: 800px) {
        grid-column: 1 / 7;
        order: 1;
        padding: 0 25px;
      }
    }
  }

  &.layout-image-left {
    & .image-container {
      grid-column: 1 / 7;
      padding: 0 25px 0 0;

      @media (max-width: 1445px) {
        padding: 0;
      }

      @media (max-width: 800px) {
        grid-column: 1 / 7;
        order: 1;
        padding: 0 25px;
      }
    }

    & .text-container {
      grid-column: 7 / 13;
      margin: 39px 0 0 0;
      padding: 0 0 0 25px;

      @media (max-width: 1445px) {
        padding: 39px 0;
        margin: 0 0 0 60px;
      }

      @media (max-width: 800px) {
        grid-column: 1 / 7;
        order: 2;

        padding: 39px 25px;
        margin: 0;
      }
    }
  }

  & .text-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;

    max-width: 800px;

    & .title-bar {
      display: flex;
      flex-direction: row;
      margin: 0 0 22px 0;

      & p {
        margin: 0;
      }

      & .spacer {
        margin: 0 10px;
      }
    }

    & .title {
      & h1 {
        @media (max-width: 999px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }

    & .text {
      margin: 20px 0 0 0;

      & p {
        font-size: 18px;
        line-height: 30px;

        text-align: justify;

        @media (max-width: 999px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  & .image-container {
    & img:not([aria-hidden="true"]) {
      border: 39px solid #efefef;
      box-sizing: border-box;

      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 999px) {
        border: 27px solid #efefef;
      }
    }
  }

  & .caption-container {
    & p {
      margin: 0;
    }

    @media (max-width: 800px) {
      margin: 100px 0 0 0;
    }
  }
`;

export const EditorialCover = ({
  id,
  editorialCoverImage,
  editorialTitle,
  editorialText,
  editorialCredits,
  imagePosition,
  articleCategories,
  index,
  pageType,
}) => {
  return (
    <EditorialCoverContainer
      className={`module editorial-cover grid-12 layout-image-${imagePosition}`}
    >
      <div className="image-container">
        {editorialCoverImage.fluid !== null && (
          <AspectRatioImageContainer image={editorialCoverImage}>
            <GatsbyImage
              // className={ImageOrientation(editorialCoverImage)}
              image={editorialCoverImage.gatsbyImageData}
              // src={editorialCoverImage.fluid.src}
              alt={
                editorialCoverImage.alt !== null ? editorialCoverImage.alt : ``
              }
              fetchpriority={index <= 1 ? `high` : ``}
              loading={index <= 1 ? `eager` : `lazy`}
            />
          </AspectRatioImageContainer>
        )}
      </div>

      <div className="text-container">
        <div className="title-text-container">
          <div className="title-bar tag gotham-bold uppercase">
            <p>{pageType === `article` && `Journal`}</p>

            {articleCategories !== null && (
              <>
                <p className="spacer">|</p>
                <p>{articleCategories}</p>
              </>
            )}
          </div>

          <div className="title">
            <PrismicRichText
              field={editorialTitle}
              components={{
                heading2: ({ children, index }) => (
                  <h1 className="article-title">{children}</h1>
                ),
              }}
            />
          </div>

          <div className="text">
            <PrismicRichText field={editorialText} />
          </div>
        </div>

        <div className="caption-container small uppercase">
          <PrismicRichText field={editorialCredits} />
        </div>
      </div>
    </EditorialCoverContainer>
  );
};
