import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

const Article = styled.div`
  & .image-container {
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .title-bar {
    display: flex;
    flex-direction: row;

    & p {
      margin: 0;
    }

    & .spacer {
      margin: 0 10px;
    }
  }

  & .text-container {
    margin: 20px 0 0 0;

    & .article-title {
      margin: 25px 0 0 0;

      @media (max-width: 999px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    & .article-excerpt {
      margin: 20px 0 25px 0;
    }
  }

  // @media (max-width: 800px) {
  //   margin: 0 40px 0 0;
  //   max-width: 250px;
  // }
`;

export const SingleArticle = ({ article, currentLanguage, index }) => {
  const categories = article.data.categories
    .filter(
      (category) =>
        category.category.document !== null &&
        category.category.document !== undefined
    )
    .filter(
      (category) =>
        category.category.document.data !== null &&
        category.category.document.data !== undefined
    )
    .map((category, index) => (
      <span key={`single_article_category_${index}_${article.id}`}>
        <Link to={category.category.document.url}>
          {category.category.document.data.title.text}
        </Link>
      </span>
    ));

  return (
    <Article className="single-article">
      <div className="image-container">
        <Link to={article.url}>
          <AspectRatioImageContainer image={null} padding={140}>
            {/* <GatsbyImage image={article.data.thumbnail.gatsbyImageData} alt={article.data.thumbnail.alt}/> */}
            <GatsbyImage
              image={article.data.thumbnail.gatsbyImageData}
              // src={article.data.thumbnail.fluid.src}
              alt={
                article.data.thumbnail.alt !== null
                  ? article.data.thumbnail.alt
                  : ``
              }
              fetchpriority={index <= 1 ? `high` : ``}
              loading={index <= 1 ? `eager` : `lazy`}
            />
          </AspectRatioImageContainer>
        </Link>
      </div>

      <div className="text-container">
        <div className="title-bar tag gotham-bold uppercase">
          <p>
            <Link to={`/${currentLanguage}/journal/`}>Journal</Link>
          </p>

          {categories.length > 0 && (
            <>
              <p className="spacer">|</p>
              <p>{categories}</p>
            </>
          )}
        </div>

        <PrismicRichText
          field={article.data.title.richText}
          components={{
            heading1: ({ children, index }) => (
              <h3 className="article-title">{children}</h3>
            ),
          }}
        />
        <PrismicRichText
          field={article.data.excerpt_text.richText}
          components={{
            paragraph: ({ children, index }) => (
              <p className="article-excerpt">{children}</p>
            ),
          }}
        />

        <div className="call-to-action">
          <Link to={article.url} className="uppercase small">
            {currentLanguage === `en` ? `See More` : `Voir Plus`}
          </Link>
        </div>
      </div>
    </Article>
  );
};
