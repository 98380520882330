import React, { useEffect, useContext, useState, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useInView } from "react-intersection-observer";
import { useMouse } from "react-use";
import { GatsbyImage } from "gatsby-plugin-image";

// Context
import { HeaderColor } from "../context/header-color";
import { CurrentLanguage } from "../context/current-language";

// Menu Data
import { UseEnglishActivitiesData } from "../hooks/useEnglishActivitiesData";
import { UseFrenchActivitiesData } from "../hooks/useFrenchActivitiesData";

const ActivitiesContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  transition: 250ms background-color ease;

  margin: 0 0 150px 0;
  padding: 0 0 150px 0;

  @media (max-width: 999px) {
    padding: 0 0 100px 0;
  }

  & .wrapper-container {
    padding: 150px 40px 0 40px;

    @media (max-width: 999px) {
      padding: 100px 40px 0 40px;
    }

    @media (max-width: 800px) {
      padding: 100px 0 0 0;
    }
  }

  & .text-container {
    max-width: 1290px;
    margin: 0 0 150px 0;

    & .title {
      margin: 0 0 18px 0;
    }

    & .text {
    }

    @media (max-width: 999px) {
      margin: 0 0 130px 0;
    }

    @media (max-width: 800px) {
      margin: 0 0 100px 0;
      padding: 0 25px;

      & .title {
        margin: 0;
      }

      & .text {
        display: none;
      }
    }
  }

  & .listings-container {
    & ol {
      max-width: 1300px;
      margin: 0 0 0 auto;

      position: relative;

      @media (max-width: 1445px) {
        max-width: 1100px;
      }

      @media (max-width: 999px) {
        max-width: 785px;
      }

      @media (max-width: 800px) {
        display: flex;
        flex-direction: row;

        max-width: 100%;
        margin: 0;

        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        padding: 0 25px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      & li {
        display: grid;
        grid-template-columns: 300px auto;
        grid-column-gap: 20px;

        border-top: 1px solid #09184f;
        padding: 20px 0;

        @media (max-width: 800px) {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;

          padding: 0;
          margin: 0 40px 0 0;
          border-top: 0;

          min-width: 50vw;
          scroll-snap-align: center;

          & h2 {
            font-size: 30px;
            line-height: 40px;
          }

          & .tag {
            margin: 20px 0;
          }
        }

        @media (max-width: 500px) {
          min-width: 70vw;
        }

        & .activity-title-container {
        }

        & .activity-text-container {
          max-width: 575px;
          margin: 0 0 0 auto;

          @media (max-width: 999px) {
            max-width: 430px;
          }

          @media (max-width: 800px) {
            max-width: 100%;
          }

          & p {
            &:first-of-type {
              margin-top: 0;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
`;

const DesktopCaption = styled.div`
  z-index: 100;

  cursor: none;
  pointer-events: none;

  overflow: hidden;

  & .gatsby-image-wrapper {
    position: absolute;
    width: 500px;
    height: 500px;

    object-fit: contain;

    transition: 50ms top ease, 50ms left ease;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const SingleActivityContainer = ({
  activity,
  setActiveProject,
  index,
  currentLanguage,
}) => (
  <li
    onMouseEnter={() => setActiveProject(index)}
    onMouseLeave={() => setActiveProject(null)}
  >
    <div className="activity-title-container">
      <PrismicRichText
        field={activity.data.title.richText}
        components={{
          heading1: ({ children }) => (
            <h2 className="activity-title">
              <Link to={activity.url}>{children}</Link>
            </h2>
          ),
        }}
      />
      <p className="tag uppercase">
        <Link to={activity.url}>
          {currentLanguage === `en` ? `Discover` : `Découvrir`}
        </Link>
      </p>
    </div>

    <div className="activity-text-container">
      <PrismicRichText field={activity.data.text.richText} />
    </div>
  </li>
);

export const Activities = ({ id, activityTitle, activityText }) => {
  const [headerColor, setHeaderColor] = useContext(HeaderColor);
  const [backgroundColor, setBackgroundColor] = useState(`#fff`);

  const { ref, inView, entry } = useInView({
    trackVisibility: true,
    delay: 100,
    threshold: [0.1],
  });

  const [activeProject, setActiveProject] = useState(null);
  const activeProjectRef = useRef(null);
  const activeProjectRefMouseData = useMouse(activeProjectRef);

  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const englishActivitiesData = UseEnglishActivitiesData();
  const frenchActivitiesData = UseFrenchActivitiesData();
  const [activitiesData, setActivitiesData] = useState(englishActivitiesData);

  useEffect(() => {
    if (currentLanguage === `en`) {
      setActivitiesData(englishActivitiesData);
    } else {
      setActivitiesData(frenchActivitiesData);
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (entry !== undefined) {
      if (entry.boundingClientRect.top <= 79 && entry.isIntersecting) {
        setHeaderColor(`#efefef`);
        setBackgroundColor(`#efefef`);
      } else {
        if (headerColor !== `#fff`) {
          setHeaderColor(`#fff`);
          setBackgroundColor(`#fff`);
        }
      }
    }
  }, [entry]);

  const content = activitiesData.prismicActivitiesModule.data.activities
    .filter((activity) => activity.activity.document !== null)
    .map((activity, index) => (
      <SingleActivityContainer
        activity={activity.activity.document}
        index={index}
        key={`single_activity_category_${index}_${id}`}
        activeProject={activeProject}
        setActiveProject={setActiveProject}
        currentLanguage={currentLanguage}
      />
    ));

  const thumbnails = activitiesData.prismicActivitiesModule.data.activities
    .filter((activity) => activity.activity.document !== null)
    .map((activity) => activity.activity.document.data.thumbnail);

  return (
    <ActivitiesContainer className="" bgColor={backgroundColor}>
      <div className="wrapper-container" ref={ref}>
        <div className="text-container">
          <div className="title">
            <PrismicRichText field={activityTitle} />
          </div>

          <div className="text">
            <PrismicRichText
              field={activityText}
              components={{
                paragraph: ({ children, index }) => <h3>{children}</h3>,
              }}
            />
          </div>
        </div>

        <div className="listings-container">
          <ol ref={activeProjectRef}>
            {content}

            <DesktopCaption>
              {activeProject !== null && (
                <GatsbyImage
                  image={thumbnails[activeProject].gatsbyImageData}
                  alt={
                    thumbnails[activeProject].alt !== null
                      ? thumbnails[activeProject].alt
                      : ``
                  }
                  style={{
                    top: `${activeProjectRefMouseData.elY - 40}px`,
                    left: `${activeProjectRefMouseData.elX + 40}px`,
                  }}
                />
              )}
            </DesktopCaption>
          </ol>
        </div>
      </div>
    </ActivitiesContainer>
  );
};
