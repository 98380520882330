import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";

// Context
import { HeaderColor } from "../context/header-color";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

const PushStoryContainer = styled.div`
  background-color: ${(props) => props.bgColor};

  & > .grid-12 {
    padding: 100px;

    @media (max-width: 1445px) {
      padding: 80px;
    }

    @media (max-width: 999px) {
      padding: 55px;
    }

    @media (max-width: 800px) {
      padding: 90px 25px;
    }
  }

  & .image-container,
  & .text-container {
    position: relative;

    grid-column: span 6;
  }

  & .inner-text-container {
    position: sticky;
    top: 120px;

    max-width: 585px;

    padding: 0 0 0 60px;
    margin: 0 auto;

    & .tag {
      margin: 0 0 1em 0;
    }

    @media (max-width: 999px) {
      padding: 0 0 0 40px;

      & h3 {
        font-size: 30px;
        line-height: 40px;
      }
    }

    @media (max-width: 800px) {
      padding: 0;
      margin: 0;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 800px) {
    & .text-container {
      margin: 40px 0 0 0;
    }
  }
`;

export const PushStory = ({ content, currentLanguage, index }) => {
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  const { ref, inView, entry } = useInView({
    trackVisibility: true,
    delay: 100,
    threshold: [0.1],
  });

  useEffect(() => {
    if (entry !== undefined) {
      if (entry.boundingClientRect.top <= 79 && entry.isIntersecting) {
        setHeaderColor(content.primary.background_color);
      } else {
        if (headerColor !== `#fff`) {
          setHeaderColor(`#fff`);
        }
      }
    }
  }, [entry]);

  if (content.primary.story.document !== null) {
    return (
      <PushStoryContainer
        className="module push-story padding-inherit"
        bgColor={content.primary.background_color}
        ref={ref}
      >
        <div className="grid-12">
          <div className="image-container">
            <Link to={content.primary.story.document.url}>
              <AspectRatioImageContainer image={null} padding={140}>
                {content.primary.story.document.data.thumbnail.fluid !==
                  null && (
                  <GatsbyImage
                    image={
                      content.primary.story.document.data.thumbnail
                        .gatsbyImageData
                    }
                    // src={
                    //   content.primary.story.document.data.thumbnail.fluid.src
                    // }
                    alt={
                      content.primary.story.document.data.thumbnail.alt !== null
                        ? content.primary.story.document.data.thumbnail.alt
                        : ``
                    }
                    fetchpriority={index <= 1 ? `high` : ``}
                    loading={index <= 1 ? `eager` : `lazy`}
                  />
                )}
              </AspectRatioImageContainer>
            </Link>
          </div>

          <div className="text-container">
            <div className="inner-text-container">
              <p className="tag uppercase gotham-bold">JOURNAL</p>

              <PrismicRichText
                field={content.primary.story.document.data.title.richText}
                components={{
                  heading1: ({ children, index }) => (
                    <h3 className="article-title">{children}</h3>
                  ),
                }}
              />

              <PrismicRichText
                field={
                  content.primary.story.document.data.excerpt_text.richText
                }
              />

              <p>
                <Link
                  to={content.primary.story.document.url}
                  className="uppercase small"
                >
                  {currentLanguage === `en` ? `See More` : `Voir Plus`}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </PushStoryContainer>
    );
  } else {
    return null;
  }
};
