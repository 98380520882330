import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// SEO
import { PageSeo } from "../components/global/page-seo";

// Utils
import kebabCase from "lodash.kebabcase";

// Context
import { CurrentLanguage } from "../components/context/current-language";
import { PageColor } from "../components/context/page-color";
import { HeaderColor } from "../components/context/header-color";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { DetermineModule } from "../components/utils/determine-module";

const Page = styled.div`
  &.page {
    margin: 79px 0 0 0;
  }
`;

const Activity = ({ data }) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  const activityCategories = data.prismicActivity.data.categories
    .filter(
      (category) =>
        category.category.document !== null &&
        category.category.document !== undefined
    )
    .filter(
      (category) =>
        category.category.document.data !== null &&
        category.category.document.data !== undefined
    )
    .map((category, index) => (
      <span key={`single_article_category_${index}_${data.prismicActivity.id}`}>
        <Link to={category.category.document.url}>
          {category.category.document.data.title.text}
        </Link>
      </span>
    ));

  const activityLinkedSections = data.prismicActivity.data.body
    .filter((section) => section.slice_type === "linked_section")
    .map((section, index) => (
      <li
        className="linked-section-link"
        key={`single_linked_section_${index}_${data.prismicActivity.id}`}
      >
        <h3>
          <span className="line" />
          <button
            onClick={() => {
              if (typeof window !== `undefined`) {
                let dims = document
                  .querySelector(
                    `#linked-section-${kebabCase(
                      section.primary.link_title.text
                    )}`
                  )
                  .getBoundingClientRect();
                window.scrollTo({ top: dims.top - 100, behavior: "smooth" });
              }
            }}
          >
            {section.primary.link_title.text}
          </button>
        </h3>
      </li>
    ));

  const content = data.prismicActivity.data.body.map((content, index) => (
    <DetermineModule
      content={content}
      index={index}
      key={`single_module_${index}_${data.prismicActivity.id}`}
      activityLinkedSections={activityLinkedSections}
      activityCategories={activityCategories}
      pageType={`activity`}
      currentLanguage={currentLanguage}
    />
  ));

  return (
    <>
      <PageSeo
        title={data.prismicActivity.data.title.text}
        image={null}
        description={null}
        url={data.prismicActivity.url}
      />
      <Page className="page">{content}</Page>
    </>
  );
};

export default withPrismicPreview(Activity);

export const query = graphql`
  query ActivityQuery($id: String, $lang: String) {
    prismicActivity(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          text
        }
        categories {
          category {
            document {
              ... on PrismicActivityCategory {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicActivityDataBodyActivityCover {
            id
            slice_type
            primary {
              activity_title {
                richText
              }
              activity_text {
                richText
              }
              activity_image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData
                fluid {
                  aspectRatio
                  srcSet
                  src
                }
                gatsbyImageData
              }
            }
          }
          ... on PrismicActivityDataBodyLinkedSection {
            id
            slice_type
            primary {
              link_title {
                richText
                text
              }
              link_text {
                richText
              }
              link_image {
                alt
                fluid {
                  aspectRatio
                  src
                  srcSet
                }
                gatsbyImageData
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicActivityDataBodyBigPush {
            id
            slice_type
            primary {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                        gatsbyImageData
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              big_push_title {
                richText
              }
              call_to_action {
                richText
              }
            }
          }
          ... on PrismicActivityDataBodyVideo {
            id
            slice_type
            primary {
              video {
                type
                html
                embed_url
                provider_name
                id
              }
              video_size
            }
          }
          ... on PrismicActivityDataBodyCover {
            id
            slice_type
            primary {
              cover_image {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
              }
              cover_text {
                richText
              }
              cover_title {
                richText
              }
              cover_call_to_action {
                richText
              }
              image_position
              link {
                url
              }
            }
          }
          ... on PrismicActivityDataBodyEdit {
            id
            slice_type
            primary {
              background_color
              journal_edit {
                type
                document {
                  ... on PrismicJournalEdit {
                    id
                    data {
                      title {
                        richText
                      }
                      articles {
                        article {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                              }
                            }
                            ... on PrismicArticle {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicJournalCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicJournalCategory {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                              }
                            }
                            ... on PrismicActivity {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicProject {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicActivityDataBodyEditorialCover {
            id
            slice_type
            primary {
              editorial_credits {
                richText
              }
              editorial_text {
                richText
              }
              editorial_title {
                richText
              }
              image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  aspectRatio
                }
              }
              image_position
            }
          }
          ... on PrismicActivityDataBodyEditorialImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicActivityDataBodyEditorialImageWithText {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
              text {
                richText
              }
            }
          }
          ... on PrismicActivityDataBodyEditorialImages {
            id
            slice_type
            primary {
              editorial_image_left {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              editorial_image_right {
                dimensions {
                  height
                  width
                }
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              image_left_background
              image_right_background
            }
          }
          ... on PrismicActivityDataBodyEditorialNote {
            id
            slice_type
            primary {
              editorial_note_title
              editorial_note_text {
                richText
              }
            }
          }
          ... on PrismicActivityDataBodyEditorialQuote {
            id
            slice_type
            primary {
              editorial_quote {
                richText
              }
            }
          }
          ... on PrismicActivityDataBodyEditorialRow {
            id
            slice_type
            primary {
              editorial_row_title {
                richText
              }
            }
            items {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 800)
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicActivityDataBodyEditorialText {
            id
            slice_type
            primary {
              editorial_text {
                richText
              }
            }
          }
          ... on PrismicActivityDataBodyProductRowCarousel {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicActivityDataBodyProductRow {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicActivityDataBodyProductCardLarge {
            id
            slice_type
            primary {
              orientation
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
              }
              orientation
            }
          }
          ... on PrismicActivityDataBodyEditorialIntroduction {
            id
            slice_type
            primary {
              editorial_introduction_text {
                richText
              }
            }
          }
          ... on PrismicActivityDataBodyPushStory {
            id
            slice_type
            primary {
              background_color
              story {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      thumbnail {
                        alt
                        gatsbyImageData
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                        dimensions {
                          height
                          width
                        }
                      }
                      excerpt_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicActivityDataBodyTitleAndText {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
          }
          ... on PrismicActivityDataBodyActivities {
            id
            slice_type
            primary {
              activity_title {
                richText
              }
              activity_text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
