import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import fetch from "isomorphic-fetch";

// Context
import { CurrentLanguage } from "./src/components/context/current-language";
import { StoreProvider } from "./src/components/context/store";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
// import Cart from "./src/pages/cart";

// Templates
import ActivityCategory from "./src/templates/activities/category";
import JournalCategory from "./src/templates/journal/category";
import Activity from "./src/templates/activity";
import ArticleTemplate from "./src/templates/article";
import Collection from "./src/templates/collection";
import Homepage from "./src/templates/homepage";
import JoinUs from "./src/templates/join-us";
import Journal from "./src/templates/journal";
import Page from "./src/templates/page";
// import Product from "./src/templates/product";
import Project from "./src/templates/project";

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new createHttpLink({
    uri: `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-04/graphql.json`,
    headers: {
      "X-Shopify-Storefront-Access-Token":
        process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
      Accept: "application/graphql",
    },
    fetch,
  }),
});

// New StateManagementComponent
const StateManagementComponent = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = React.useState("en");

  return (
    <CurrentLanguage.Provider value={[currentLanguage, setCurrentLanguage]}>
      {children}
    </CurrentLanguage.Provider>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver,
            componentResolver: componentResolverFromMap({
              // cart: Cart,
              activity_category: ActivityCategory,
              journal_category: JournalCategory,
              activity: Activity,
              article: ArticleTemplate,
              collection: Collection,
              homepage: Homepage,
              join_us: JoinUs,
              journal: Journal,
              page: Page,
              // product: Product,
              project: Project,
            }),
          },
        ]}
      >
        <StateManagementComponent>
          <ApolloProvider client={apolloClient}>
            <StoreProvider>{element}</StoreProvider>
          </ApolloProvider>
        </StateManagementComponent>
      </PrismicPreviewProvider>
    </React.Suspense>
  );
};

export const onClientEntry = () => {
  // Force disable hydration warning
  window.__GATSBY_HYDRATION = {
    disabled: true,
  };
};
