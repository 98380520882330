import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/global/page-seo";

// Components
import { DetermineModule } from "../components/utils/determine-module";

// Context
import { CurrentLanguage } from "../components/context/current-language";
import { PageColor } from "../components/context/page-color";
import { HeaderColor } from "../components/context/header-color";

const Page = styled.div`
  @media (max-width: 800px) {
    &.page {
      margin: 0;
    }
  }
`;

const ArticleTemplate = ({ data, pageContext }) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  const articleCategories = data.prismicArticle.data.categories
    .filter(
      (category) =>
        category.category.document !== null &&
        category.category.document !== undefined
    )
    .filter(
      (category) =>
        category.category.document.data !== null &&
        category.category.document.data !== undefined
    )
    .map((category, index) => (
      <span key={`single_article_category_${index}_${data.prismicArticle.id}`}>
        <Link to={category.category.document.url}>
          {category.category.document.data.title.text}
        </Link>
      </span>
    ));

  const content = data.prismicArticle.data.body.map((content, index) => (
    <DetermineModule
      content={content}
      index={index}
      key={`single_module_${index}_${data.prismicArticle.id}`}
      pageType={`article`}
      articleCategories={articleCategories}
      currentLanguage={currentLanguage}
    />
  ));

  return (
    <>
      <PageSeo
        title={data.prismicArticle.data.title.text}
        image={null}
        description={null}
        url={data.prismicArticle.url}
      />
      <Page className="page">{content}</Page>
    </>
  );
};

export default withPrismicPreview(ArticleTemplate);

export const query = graphql`
  query ArticleQuery($id: String, $lang: String) {
    prismicArticle(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          richText
          text
        }
        categories {
          category {
            document {
              ... on PrismicJournalCategory {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicArticleDataBodyBigPush {
            id
            slice_type
            primary {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                        gatsbyImageData
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              big_push_title {
                richText
              }
              call_to_action {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyCover {
            id
            slice_type
            primary {
              cover_image {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
              }
              cover_text {
                richText
              }
              cover_title {
                richText
              }
              cover_call_to_action {
                richText
              }
              image_position
              link {
                url
              }
            }
          }
          ... on PrismicArticleDataBodyEdit {
            id
            slice_type
            primary {
              background_color
              journal_edit {
                type
                document {
                  ... on PrismicJournalEdit {
                    id
                    data {
                      title {
                        richText
                      }
                      articles {
                        article {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                              }
                            }
                            ... on PrismicArticle {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicJournalCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicActivity {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicProject {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyActivities {
            id
            slice_type
            primary {
              activity_title {
                richText
              }
              activity_text {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyEditorialCover {
            id
            slice_type
            primary {
              editorial_credits {
                richText
              }
              editorial_text {
                richText
              }
              editorial_title {
                richText
              }
              image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  aspectRatio
                }
              }
              image_position
            }
          }
          ... on PrismicArticleDataBodyEditorialImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicArticleDataBodyEditorialImageWithText {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
              text {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyEditorialImages {
            id
            slice_type
            primary {
              editorial_image_left {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              editorial_image_right {
                dimensions {
                  height
                  width
                }
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              image_left_background
              image_right_background
            }
          }
          ... on PrismicArticleDataBodyEditorialNote {
            id
            slice_type
            primary {
              editorial_note_title
              editorial_note_text {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyEditorialQuote {
            id
            slice_type
            primary {
              editorial_quote {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyVideo {
            id
            slice_type
            primary {
              video {
                type
                html
                embed_url
                provider_name
                id
              }
              video_size
            }
          }
          ... on PrismicArticleDataBodyEditorialRow {
            id
            slice_type
            primary {
              editorial_row_title {
                richText
              }
            }
            items {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 800)
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyEditorialText {
            id
            slice_type
            primary {
              editorial_text {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyProductRowCarousel {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicArticleDataBodyProductRow {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicArticleDataBodyProductCardLarge {
            id
            slice_type
            primary {
              orientation
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
              }
              orientation
            }
          }
          ... on PrismicArticleDataBodyEditorialIntroduction {
            id
            slice_type
            primary {
              editorial_introduction_text {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyPushStory {
            id
            slice_type
            primary {
              background_color
              story {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      thumbnail {
                        alt
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                        gatsbyImageData
                        dimensions {
                          height
                          width
                        }
                      }
                      excerpt_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyTitleAndText {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyNewsletter {
            id
            slice_type
            primary {
              newsletter {
                document {
                  ... on PrismicNewsletterSignup {
                    id
                    data {
                      background_color
                      email_placeholder
                      newsletter_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
