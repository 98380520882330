import React, { useEffect, useRef, useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { useKeyPressEvent } from "react-use";
import { GatsbyImage } from "gatsby-plugin-image";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

// Icons
import { ArrowNext, ArrowPrev, Separator } from "../components/icons/arrows";
import { CloseIcon } from "../components/icons/close-icon";

const LightboxContainer = styled.div`
  position: relative;

  background-color: #efefef;
  padding: 45px 0 0 0;

  z-index: 100;
  height: 100%;
  min-height: ${(props) => props.height}px;
  width: 100%;

  @media (max-width: 900px) {
    align-items: flex-start;
  }

  @media (max-width: 800px) {
    padding: 25px 0;
  }

  & .slideshow-container {
    position: relative;

    width: 100%;
    min-height: calc(${(props) => props.height}px - 45px - 45px);

    padding: 0 50px;

    @media (max-width: 1445px) {
      padding: 0 40px;
    }

    @media (max-width: 999px) {
      padding: 0 30px;
    }

    @media (max-width: 900px) {
      height: auto;
      padding: 30px;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    }

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      min-height: calc(${(props) => props.height}px - 45px - 45px);

      & .text-container {
        grid-column: 1 / 5;
        padding: 0 0 0 85px;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        @media (max-width: 1200px) {
          padding: 0 0 0 55px;
        }

        @media (max-width: 999px) {
          max-width: 355px;
        }

        @media (max-width: 900px) {
          grid-column: 1 / 13;
          margin: 0 0 90px 0;
        }

        & .navigation {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          @media (max-width: 500px) {
            display: none;
          }

          & button {
            padding: 0;
            width: 25px;
            height: 11px;

            line-height: 1;

            &:first-of-type {
              text-align: left;
            }

            &:last-of-type {
              text-align: right;
            }

            & svg {
              height: 13px;
              width: auto;
            }
          }

          & svg {
            &.line {
              height: 11px;
              width: auto;

              margin: 0 5px;
            }
          }

          & p {
            font-feature-settings: "tnum";

            margin: 0;
            line-height: 9px;
            height: 9px;

            &:last-of-type {
              text-align: right;
            }

            &.spacer {
              text-align: center;
              width: 20px;
            }
          }
        }
      }
    }
  }

  & .single-image-container {
    position: relative;

    width: 100%;
    max-height: calc(${(props) => props.height}px - 45px - 45px);

    margin: 0 0 0 auto;

    @media (max-width: 900px) {
      max-height: fit-content;
    }

    & img:not([aria-hidden="true"]) {
      border: 39px solid #fff;
      box-sizing: border-box;
      margin: 0 0 0 auto;

      max-height: calc(${(props) => props.height}px - 45px - 45px);

      object-fit: contain;
      object-position: right;

      width: fit-content;
      height: 100%;
      max-width: 100%;

      @media (max-width: 999px) {
        border: 27px solid #fff;
      }

      @media (max-width: 900px) {
        max-height: 100%;
      }

      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: calc(${(props) => props.height}px - 45px - 45px);

  grid-column: 6 / 13;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div {
    position: relative;

    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    grid-column: 1 / 13;
    max-height: 100%;
  }
`;

const CloseLightbox = styled.div`
  position: absolute;
  top: 34px;
  left: 30px;

  z-index: 200;

  padding: 20px 20px 20px 0;

  &:hover {
    & svg {
      fill: #ff0000;
    }
  }

  @media (max-width: 900px) {
    padding: 0 20px 20px 0;
  }
`;

Modal.setAppElement("#___gatsby");

const Project = ({ data, location }) => {
  const height = use100vh();

  // Slideshow Reference
  const slideRef = useRef();

  const [totalSlides, setTotalSlides] = useState(0);
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);

  useEffect(() => {
    setTotalSlides(data.prismicProject.data.images.length);
  }, []);

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined && images.length > 1) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined && images.length > 1) {
      slideRef.current.goNext();
    }
  };

  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  const images = data.prismicProject.data.images
    .filter((image) => image.image.gatsbyImageData !== null)
    .map((image, index) => {
      return (
        <div
          className={`single-image-container ${ImageOrientation(image.image)}`}
          key={`single_page_item_slideshow_${index}`}
        >
          <GatsbyImage
            image={image.image.gatsbyImageData}
            alt={image.image.alt !== null ? image.image.alt : ``}
            loading={index < 2 ? `eager` : `lazy`}
          />
        </div>
      );
    });

  const [backLink, setBackLink] = useState(`/`);
  useEffect(() => {
    if (location !== null && location !== undefined) {
      if (location.state !== null && location.state !== undefined) {
        if (location.state.backLink !== undefined) {
          setBackLink(location.state.backLink);
        }
      }
    }
  }, [location]);

  return (
    <LightboxContainer height={height}>
      <CloseLightbox>
        {backLink.includes(`/category/`) ? (
          <button onClick={() => navigate(-1)}>
            <CloseIcon />
          </button>
        ) : (
          <Link to={backLink}>
            <CloseIcon />
          </Link>
        )}
      </CloseLightbox>

      <div className="slideshow-container">
        <div className="lightbox-wrapper">
          <div className="text-container">
            <div className="inner-text-container">
              <PrismicRichText
                field={data.prismicProject.data.title.richText}
                components={{
                  heading1: ({ children, index }) => <h3>{children}</h3>,
                }}
              />
              <PrismicRichText field={data.prismicProject.data.text.richText} />
            </div>

            <div className="navigation">
              {images.length > 1 && (
                <button
                  aria-label="Previous Slide"
                  className={`previous`}
                  onClick={() => {
                    slideRef.current.goBack();
                  }}
                >
                  <ArrowPrev />
                </button>
              )}

              <p className="gotham-bold tag">{currentSlideNumber + 1}</p>
              <p className="gotham-bold tag spacer">
                <Separator />
              </p>
              <p className="gotham-bold tag">{totalSlides}</p>

              {images.length > 1 && (
                <button
                  aria-label="Next Slide"
                  className={`next`}
                  onClick={() => {
                    slideRef.current.goNext();
                  }}
                >
                  <ArrowNext />
                </button>
              )}
            </div>
          </div>

          <GalleryContainer height={height}>
            <Fade
              ref={slideRef}
              arrows={false}
              transitionDuration={0}
              pauseOnHover={false}
              autoplay={false}
              infinite={true}
              canSwipe={true}
              defaultIndex={0}
              onChange={(previous, next) => {
                setCurrentSlideNumber(next);
              }}
            >
              {images}
            </Fade>
          </GalleryContainer>
        </div>
      </div>
    </LightboxContainer>
  );
};

export default withPrismicPreview(Project);

export const query = graphql`
  query ($id: String, $lang: String) {
    prismicProject(id: { eq: $id }, lang: { eq: $lang }) {
      url
      data {
        title {
          richText
        }
        thumbnail {
          alt
          fluid {
            src
            srcSet
            aspectRatio
          }
          gatsbyImageData
          dimensions {
            height
            width
          }
        }
        excerpt_text {
          richText
        }
        text {
          richText
        }
        images {
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
            dimensions {
              height
              width
            }
          }
        }
        project_type
        categories {
          category {
            document {
              ... on PrismicActivityCategory {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
